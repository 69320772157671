import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LoginPage } from '../components/Auth/LoginPage';
import { FileList } from '../components/FileList';

import { PrivateRoute } from './PrivateRoute';

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route
        path="/files"
        element={
          <PrivateRoute>
            <FileList />
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={
          <PrivateRoute>
            <>
              <FileList />
              <Navigate to="/files" />
            </>
          </PrivateRoute>
        }
      />
    </Routes>
  );
};
