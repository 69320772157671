import * as React from 'react';
import { Layout, Space } from 'antd';
import { useScreenSize } from '../../hooks/useScreenSize';

const { Footer } = Layout;

const termsOfUseUrl = 'https://www.uhc.com/content/dam/uhcdotcom/en/npp/TOU-UHC-TIC-Private-EN.pdf';
const privacyPolicyUrl = 'https://www.uhc.com/content/dam/uhcdotcom/en/npp/OSPP-UHC-TIC-Private-EN.pdf';

export const AppFooter: React.FC = () => {
  const { md } = useScreenSize();
  return (
    <Footer
      style={{
        display: 'flex',
        minHeight: '5vh',
        padding: '8px 16px',
        alignItems: 'center',
        justifyContent: md ? 'left' : 'center',
      }}
    >
      <Space size={md ? 'middle' : 1} direction={md ? 'horizontal' : 'vertical'} style={{ textAlign: 'center' }}>
        <span>UnitedHealthcare ©{new Date().getFullYear()}</span>
        <Space
          size={'middle'}
          wrap
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <a href={termsOfUseUrl} target="_blank" rel="noreferrer" aria-label="Terms of Use">
            Terms of Use
          </a>
          <a href={privacyPolicyUrl} target="_blank" rel="noreferrer" aria-label="Privacy Policy">
            Privacy Policy
          </a>
        </Space>
      </Space>
    </Footer>
  );
};
