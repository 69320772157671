import { Grid } from 'antd';

const { useBreakpoint } = Grid;

interface IScreens {
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  xxl?: boolean;
}

export const useScreenSize = (): IScreens => {
  const screens: IScreens = useBreakpoint();

  return screens;
};
