import { useMutation, UseMutationResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { login } from '../api/ohid';
import { IUser, useAuth } from '../context/AuthContext';

/**
 * A custom hook wrapping a useMutation hook that logs a user in
 * @returns {UseMutationResult<IUser, unknown, string, unknown>}
 */
export const useLogin = (): UseMutationResult<IUser, unknown, string, unknown> => {
  const navigate = useNavigate();
  const { onLogin } = useAuth();

  // See hooks.md
  return useMutation((code: string) => login(code), {
    onSuccess: (data: IUser) => onLogin(data),
    onError: (error: unknown) => console.log(error),
    onSettled: () => navigate('/files'),
  });
};
