import { useQuery, UseQueryResult } from 'react-query';
import { getSilentAuthorizationUrl } from '../api/ohid';

/**
 * A custom hook wrapping a useQuery hook that fetches the silent authorization url
 * @returns {UseQueryResult<{ url: string }, unknown>}
 */
export const useAuthUrl = (): UseQueryResult<{ url: string }, unknown> => {
  // See hooks.md
  return useQuery('authUrl', getSilentAuthorizationUrl, {
    refetchInterval: false,
    cacheTime: Infinity,
  });
};
