import * as React from 'react';
import { Avatar, Button, Card, Popover } from 'antd';
import { LogoutButton } from './LogoutButton';
import { SettingOutlined } from '@ant-design/icons';
import { getInitials } from '../../util/helpers';

interface IUserSettingsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  profile: any;
}

const UserSettingsContent: React.FC<IUserSettingsProps> = ({ profile }) => (
  <Card bordered={false} actions={[<LogoutButton danger type="link" key="logout" />]}>
    {profile ? (
      <Card.Meta
        avatar={
          <Avatar size={'large'} gap={2}>
            {getInitials(profile.givenName, profile.familyName)}
          </Avatar>
        }
        title={<h3 style={{ margin: 0 }}>{profile.name}</h3>}
        description={
          <>
            <p>{profile.email}</p>
            <p>{profile.username}</p>
          </>
        }
      />
    ) : null}
  </Card>
);

export const UserSettings: React.FC<IUserSettingsProps> = ({ profile }) => (
  <Popover arrowPointAtCenter placement="bottom" trigger="hover" content={<UserSettingsContent profile={profile} />}>
    <Button shape="circle" size="large" icon={<SettingOutlined />} />
  </Popover>
);
