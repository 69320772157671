import * as React from 'react';
import { Layout, Space } from 'antd';
import { Greeting } from '../Auth/Greeting';
import { UserSettings } from '../Auth/UserSettings';
import { AppLogo } from './AppLogo';
import { useAuth } from '../../context/AuthContext';

const { Header } = Layout;

export const AppHeader: React.FC = () => {
  const { user } = useAuth();
  const profile = {
    givenName: user?.given_name || '',
    name: user?.name,
    username: user?.preferred_username,
    email: user?.email,
    familyName: user?.family_name || '',
  };

  return (
    <Header
      className="header"
      style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '0px 16px' }}
    >
      <AppLogo />
      <Space size={16}>
        {window.innerWidth > 750 && <Greeting name={profile.givenName} />}
        <UserSettings profile={profile} />
      </Space>
    </Header>
  );
};
