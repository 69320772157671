/* eslint-disable @typescript-eslint/no-unused-vars */
const greetings: string[] = ['Hello', 'Welcome', 'Howdy', 'Greetings', 'Hey'];

export const generateGreetingWithName = (name: string): string => {
  const randomGreetingId: number = Math.floor(Math.random() * greetings.length);
  const greeting: string = greetings[randomGreetingId];
  const greetingWithName = `${greeting}, ${name}!`;
  return greetingWithName;
};

export const getInitials = (firstName: string, lastName: string): string => {
  const initials = firstName.charAt(0) + lastName.charAt(0);
  return initials.toUpperCase();
};
