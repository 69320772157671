import * as React from 'react';
import { AppLayout } from '../components/AppLayout';
import { Navigate } from 'react-router-dom';
// import { AppLayout } from '../components/shared/Layout';
import { useAuth } from '../context/AuthContext';

interface IPrivateRouteProps {
  children: JSX.Element;
}

export const PrivateRoute = ({ children }: IPrivateRouteProps): JSX.Element => {
  const { authenticated } = useAuth();
  return authenticated ? <AppLayout>{children}</AppLayout> : <Navigate to="/" />;
};
