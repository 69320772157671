import * as React from 'react';
import { useCheckAuth } from '../hooks/useCheckAuth';
import { useLocalStorage } from '../hooks/useLocalStorage';

interface IAuthContext {
  authenticated: boolean;
  user?: IUser;
  onLogin: (user: IUser) => void;
  onLogout: () => void;
}

interface IAuthProviderProps {
  children: React.ReactNode;
}

export interface IUser {
  sub: string;
  birthdate: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  preferred_username: string;
  given_name: string;
  locale: string;
  family_name: string;
  email: string;
}

const AuthContext = React.createContext<IAuthContext | undefined>(undefined);

const AuthProvider = ({ children }: IAuthProviderProps): JSX.Element => {
  const [authenticated, setAuthenticated] = useLocalStorage('ohid', 'false');
  const [user, setUser] = useLocalStorage('user', '{}');

  const onCheck = (valid: boolean): void => {
    setAuthenticated(Boolean(valid).toString());
  };

  const checkAuth = useCheckAuth(onCheck);

  React.useEffect(() => {
    checkAuth.refetch();
  }, []);

  const onLogin = (user: IUser): void => {
    setAuthenticated('true');
    setUser(JSON.stringify(user));
  };

  const onLogout = (): void => {
    setAuthenticated('false');
    setUser(JSON.stringify({}));
  };

  const value: IAuthContext = React.useMemo(() => {
    return {
      authenticated: authenticated === 'true',
      user: JSON.parse(user),
      onLogin,
      onLogout,
    };
  }, [authenticated, user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = (): IAuthContext => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
