import axios from 'axios';

const headers = {
  Pragma: 'no-cache',
  'Access-Control-Allow-Origin': '*',
};

export const baseURL = `${window.location.origin}/api`;

// Custom axios instance. To use, call instance.{operation}('/path/after/api/', ...)
export const instance = axios.create({
  baseURL,
  headers,
});
