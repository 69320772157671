import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const reactPlugin: ReactPlugin = new ReactPlugin();
const appInsights: ApplicationInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_AI_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    maxBatchInterval: 0,
    disableFetchTracking: false,
    extensions: [reactPlugin],
  },
});

export { reactPlugin };
export { appInsights };
