import { useQuery, UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { logout } from '../api/ohid';
import { useAuth } from '../context/AuthContext';

/**
 * A custom hook wrapping a useQuery hook that logs a user out
 * @returns UseQueryResult<{ message: string }>
 */
export const useLogout = (): UseQueryResult<{ message: string }> => {
  const navigate = useNavigate();
  const { onLogout } = useAuth();

  // See hooks.md
  return useQuery('logout', logout, {
    enabled: false,
    refetchInterval: false,
    onSuccess: () => {
      navigate('/');
      onLogout();
    },
    onError: (error: unknown) => console.log(error),
  });
};
