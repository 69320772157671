import * as React from 'react';
import { Button, List, Space, Result } from 'antd';
import { useBlobStorageFiles } from '../../hooks/useBlobStorageFiles';
import { ReactPlugin, useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useAuth } from '../../context/AuthContext';
import { IBlob } from '../../util/interfaces';

export const FileList: React.FC = () => {
  const { isLoading, error, isError, refetch, data } = useBlobStorageFiles();
  const { user } = useAuth();

  const appInsights: ReactPlugin = useAppInsightsContext();

  React.useEffect(() => {
    appInsights.trackEvent({
      name: 'FileList Render',
      properties: {
        isLoading,
        fileListLength: data?.files?.length,
        isError,
        error,
        userOHID: user?.preferred_username,
        userEmail: user?.email,
        userFirstName: user?.given_name,
        userLastName: user?.family_name,
      },
    });
  });

  if (isError) {
    return (
      <div data-testid="failurePrompt">
        <Result
          title={'Server Error'}
          subTitle={
            'We’re sorry, but your request can’t be completed at this time. We’re working to resolve the issue as quickly as possible. Please try again later. Thank you.'
          }
          status="error"
          extra={
            <Button type="primary" onClick={() => refetch()}>
              Retry
            </Button>
          }
        />
      </div>
    );
  }

  if (data?.files === null || data?.files.length === 0) {
    return (
      <div data-testid="failurePrompt">
        <Result
          title={'Access Denied'}
          subTitle={
            'You do not have access to view any Machine-Readable Files. Please contact your account manager to grant the appropriate access.'
          }
          status="warning"
        />
      </div>
    );
  }

  const onFileClick = async (_e: React.MouseEvent<HTMLElement>, file: IBlob) => {
    appInsights.trackEvent({
      name: 'File Downloaded',
      properties: {
        file: file.name,
        userOHID: user?.preferred_username,
        userEmail: user?.email,
        userFirstName: user?.given_name,
        userLastName: user?.family_name,
      },
    });
  };

  return (
    <List
      data-testid="list"
      loading={isLoading}
      size="default"
      dataSource={data?.files}
      renderItem={(file: IBlob, index) => (
        <List.Item data-testid={'list-item-' + index}>
          <Space>
            <span>{index + 1}.</span>
            <a href={file.downloadUrl} onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onFileClick(e, file)}>
              {file.name}
            </a>
          </Space>
        </List.Item>
      )}
    />
  );
};
