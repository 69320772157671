import * as React from 'react';
import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { IRestProps } from '../../util/interfaces';
import { useLogout } from '../../hooks/useLogout';

export const LogoutButton: React.FC<IRestProps> = ({ ...rest }) => {
  const logout = useLogout();
  const handleLogout = (): void => {
    logout.refetch();
  };

  return (
    <Button onClick={handleLogout} icon={<LogoutOutlined />} {...rest}>
      Logout
    </Button>
  );
};
