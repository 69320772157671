import * as React from 'react';
import { Layout } from 'antd';
import { AppHeader } from './AppHeader';
import { AppFooter } from './AppFooter';

const { Content } = Layout;

export const AppLayout: React.FC = ({ children }) => {
  return (
    <Layout data-test-id="layout" style={{ minHeight: '100vh', maxHeight: '100vh' }}>
      <AppHeader />
      <Content style={{ background: '#fff', margin: 16, overflow: 'auto' }}>{children}</Content>
      <AppFooter />
    </Layout>
  );
};
