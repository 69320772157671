import * as React from 'react';
import { Button, Layout, Result } from 'antd';
import { useAuthUrl } from '../../../hooks/useAuthUrl';
import { useLocation } from 'react-router-dom';
import { useLogin } from '../../../hooks/useLogin';
import { getErrorMessage } from '../../../util/errors';
import logo from '../../../logo/UHC-Horizontal-Wordmark-White.png';

const { Content } = Layout;

export const LoginPage = (): JSX.Element => {
  const location = useLocation();
  const authUrl = useAuthUrl();
  const login = useLogin();
  const code = location.search.split('code=').pop();

  const centerStyles: React.CSSProperties = { margin: '25vh auto auto auto' };

  React.useEffect(() => {
    if (code !== undefined && code.length > 0) {
      login.mutate(code);
    }
  }, [location.pathname]);

  const LoginTitle = (): JSX.Element => (
    <span style={{ color: '#fff' }}>Welcome to the Cost Transparency Private Site</span>
  );

  const LoginErrorTitle = (): JSX.Element => <span style={{ color: '#fff' }}>Error</span>;

  return (
    <Layout style={{ minHeight: '100vh', maxHeight: '100vh' }}>
      <Content style={{ display: 'flex', background: '#002677' }}>
        {authUrl.isError ? (
          <Result
            style={centerStyles}
            status="error"
            title={<LoginErrorTitle />}
            subTitle={getErrorMessage(authUrl.error)}
            extra={<Button onClick={() => authUrl.refetch()}>Try Again</Button>}
          />
        ) : (
          <Result
            style={centerStyles}
            icon={<img style={{ width: '50vw' }} src={logo} alt="UHC Logo" />}
            title={<LoginTitle />}
            extra={
              <Button
                data-test-id="login-button"
                loading={authUrl.isLoading || authUrl.isIdle || login.isLoading}
                href={authUrl.data?.url}
              >
                {login.isLoading ? 'Please wait...' : 'Log in with One Healthcare ID'}
              </Button>
            }
          />
        )}
      </Content>
    </Layout>
  );
};
