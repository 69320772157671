import { AxiosResponse } from 'axios';
import { instance } from './axiosInstance';
import { IBlob } from '../util/interfaces';

export interface IFileResponse {
  files: IBlob[];
}

export const getFiles = async (): Promise<IFileResponse> => {
  return instance
    .get('/files/')
    .then((response: AxiosResponse<IFileResponse>) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};
