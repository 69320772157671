import * as React from 'react';
import { Space } from 'antd';
import { useScreenSize } from '../../hooks/useScreenSize';
import logo from '../../logo/UHC-UMark-Blue.png';

const WrappedLogo = (): JSX.Element => {
  const height = 42;

  return (
    <div
      style={{
        height,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <img
        data-test-id="logo"
        alt="logo"
        style={{
          height: '100%',
        }}
        src={logo}
      />
    </div>
  );
};

export const AppLogo = (): JSX.Element => {
  const { lg } = useScreenSize();

  const title = 'Transparency in Coverage';

  return (
    <Space align={'center'} size={'large'}>
      <WrappedLogo />
      {lg && <h1 style={{ margin: 0 }}>{title}</h1>}
    </Space>
  );
};
