import { IUser } from '../context/AuthContext';
import { getErrorMessage } from '../util/errors';
import { instance } from './axiosInstance';

/**
 * Asynchronous function to call the /auth/silentAuthUrl endpoint.
 * Purpose: User redirected to this url to start the log in process.
 * Used by the useAuthUrl hook (hooks/useAuthUrl.ts)
 * @returns {Promise<{ url: string }>} the silent authorization url configured on the server with env vars
 */
export const getSilentAuthorizationUrl = async (): Promise<{ url: string }> => {
  try {
    const { data } = await instance.get<{ url: string }>('/auth/authUrl');
    return data;
  } catch (error: unknown) {
    console.log(error);
    throw new Error(getErrorMessage(error));
  }
};

/**
 * Asynchronous function to call the /auth/check endpoint.
 * Purpose: check if user is logged in/has a valid session.
 * Used by the useCheckAuth hook (hooks/useCheckAuth.ts)
 * @returns {Promise<{ valid: boolean }>} valid : true if a session is active, false otherwise.
 */
export const check = async (): Promise<{ valid: boolean }> => {
  try {
    const { data } = await instance.get<{ valid: boolean }>('/auth/check');
    return data;
  } catch (error: unknown) {
    console.log(error);
    throw new Error(getErrorMessage(error));
  }
};

/**
 * Asynchronous function to call the /auth/code endpoint.
 * Purpose: exchange authorization code for access tokens and user info
 * Used by the useLogin hook (hooks/useLogin.ts)
 * @param {string} code the authorization code
 * @returns {Promise<IUser>} user data consisting of name and msid (TODO: include roles/groups)
 */
export const login = async (code: string): Promise<IUser> => {
  try {
    const { data } = await instance.post<IUser>(
      '/auth/login',
      {
        code,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return data;
  } catch (error: unknown) {
    console.log(error);
    throw new Error(getErrorMessage(error));
  }
};

/**
 * Asynchronous function to call the /auth/logout endpoint.
 * Purpose: end user session
 * Used by the useLogout hook (hooks/useLogout.ts)
 * @returns {Promise<{ message: string }>} a succcess message on logout
 */
export const logout = async (): Promise<{ message: string }> => {
  try {
    const { data } = await instance.get<{ message: string }>('/auth/logout');
    return data;
  } catch (error: unknown) {
    console.log(error);
    throw new Error(getErrorMessage(error));
  }
};
