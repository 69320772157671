import { useQuery, UseQueryResult } from 'react-query';
import { check } from '../api/ohid';

/**
 * A custom hook wrapping a useQuery hook that checks auth status of a user
 * @param onCheck a function to be called on success of the query
 * @returns {UseQueryResult<{ url: string }, unknown>}
 */
export const useCheckAuth = (onCheck: (valid: boolean) => void): UseQueryResult<{ valid: boolean }> => {
  // See hooks.md
  return useQuery('check', check, {
    enabled: false,
    refetchInterval: false,
    onSuccess: (data: { valid: boolean }) => {
      onCheck(data.valid);
    },
    onError: (error: unknown) => console.log(error),
  });
};
